import { computed, getCurrentInstance, ref } from '@vue/composition-api'

export default function useDiscoveryList() {
  const vm = getCurrentInstance().proxy

  const discoveryListTable = ref([])

  const tableColumns = [
    { text: '#', value: 'id', width: '5%' },
    { text: 'TITLE', value: 'title', width: '65%' },
    { text: 'BUTTON TEXT', value: 'buttonText', width: '20%' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
      width: '10%',
    },
  ]

  const discoveries = computed({
    get: () => vm.$store.getters['discoveries/items'],
  })
  const loading = computed({
    get: () => vm.$store.getters['discoveries/loading'],
  })
  const meta = computed({
    get: () => vm.$store.getters['discoveries/meta'],
  })
  const filters = computed({
    get: () => vm.$store.getters['discoveries/filters'],
  })
  const options = computed({
    get: () => vm.$store.getters['discoveries/options'],
    set: val => vm.$store.dispatch('discoveries/setOptions', { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters['discoveries/search'],
    set: val => vm.$store.dispatch('discoveries/setSearch', val),
  })
  const totalDiscoveryListTable = computed({
    get: () => vm.$store.getters['discoveries/meta'].total,
  })

  const fetchItems = () => vm.$store.dispatch('discoveries/fetchItems')
  const setOptions = val => vm.$store.dispatch('discoveries/setOptions', val)
  const setFilters = val => vm.$store.dispatch('discoveries/setFilters', val)

  const init = () => {
    fetchItems()
  }

  const destroy = () => {}

  return {
    discoveryListTable,
    tableColumns,

    totalDiscoveryListTable,
    loading,

    fetchItems,

    meta,
    discoveries,
    options,
    filters,
    search,

    setOptions,
    setFilters,

    init,
    destroy,
  }
}
